import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Team = () => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiTeams(sort: {fields: order, order: ASC}) {
          edges {
            node {
              id
              first
              order
              last
              telefon
              email
              positions {
                rolle
              }
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: DOMINANT_COLOR
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        <div className="bg-white">
          <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
            <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
              <div className="space-y-5 sm:space-y-4">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                  Unser Team
                </h2>
                <p className="text-xl text-gray-500">
                  Die Personen hinter der Genossenschaft Rübenumschlag Mittelland.
                </p>
              </div>
              <div className="lg:col-span-2">
                <ul className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
                  {data.allStrapiTeams.edges.map((team) => (
                    <li className="sm:py-8">
                      <div className="space-y-4 sm:grid sm:grid-cols-2 sm:items-start sm:gap-6 sm:space-y-0">
                        <div className="aspect-w-3 aspect-h-2">
                          <GatsbyImage
                            image={
                              team.node.image.localFile.childImageSharp.gatsbyImageData
                            }
                            style={{ objectFit: "cover", position: "absolute" }}
                            alt="Rüebenumschlag Mittelland"
                          />
                        </div>
                        <div className="sm:col-span-1">
                          <div className="space-y-2">
                            <div className="pb-2">
                              <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
                                <h3 className="ml-2 mt-2 text-xl leading-6 font-medium text-gray-900">
                                  {team.node.first} {team.node.last}
                                </h3>
                              </div>
                            </div>
                            <div className="text-base">
                              <div className="">
                                <p className="text-green-700">
                                  <ul className="list-none">
                                    {team.node.positions.map((rolle) => (
                                      <li className="font-medium">
                                        {rolle.rolle}
                                      </li>
                                    ))}
                                  </ul>
                                </p>
                                <span className="inline-block h-1 w-10 rounded bg-green-600 mt-2 mb-2"></span>
                                <ul className="list-none text-gray-500">
                                  {team.node.telefon && (
                                    <li>
                                      <span className="font-normal">
                                        Telefon
                                      </span>
                                      :{" "}
                                      <a href={`tel: ${team.node.telefon}`}>
                                        {team.node.telefon}
                                      </a>
                                    </li>
                                  )}
                                  {team.node.email && (
                                    <li>
                                      <span className="font-normal">Email</span>
                                      : {team.node.email}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        
      </>
    )}
  />
);

export default Team;
