import * as React from "react";
import Markdown from "react-markdown";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../components/container";
import Team from "../components/team/team";

function PageView({ page }) {
  const slug = page.slug;

  if (slug === "team") {
    return (
      <>
        <Team />
      </>
    );
  } else {
    return (
      <>
        <Container>
          <div className="px-6 py-6 sm:px-4 lg:px-8 lg:py-6">
            <div className="relative shadow sm:rounded">
              <div className="absolute inset-0">
                <GatsbyImage
                  image={page.image.localFile.childImageSharp.gatsbyImageData}
                  className="h-full w-full object-cover"
                  alt="Rüebenumschlag Mittelland"
                />
                <div
                  className="absolute inset-0"
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.00) 10%, #000000 100%)`,
                  }}
                ></div>
              </div>

              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">{page.title}</span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto prose prose-xl text-center text-xl text-gray-100 sm:max-w-3xl">
                  <Markdown source={page.excerpt} escapeHtml={false} />
                </p>
              </div>
            </div>
            {page.imageCredits && (
              <div className="mt-3 flex text-xs text-gray-400">
                <svg
                  className="flex-none w-4 h-4 text-gray-300"
                  x-description="Heroicon name: solid/camera"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="ml-2">{page.imageCredits}</span>
              </div>
            )}
            <div className="mt-6 prose text-gray-600 mx-auto">
              <Markdown source={page.body} escapeHtml={false} />
            </div>

            {page.content.map((section) => (
              <div>
                <div className="mt-6 prose text-gray-600 mx-auto">
                  <h2>{section.title}</h2>

                  <Markdown source={section.content} escapeHtml={false} />

                  <GatsbyImage
                    image={section.picture.localFile.childImageSharp.gatsbyImageData}
                    className="h-full w-full object-cover"
                    alt="Rüebenumschlag Mittelland"
                  />
                </div>
              </div>
            ))}
            <div className="mt-6 prose text-gray-600 mx-auto">
              <ul>
                {page.attachment.map((attachment) => (
                  <li>
                    {attachment.file && (
                      <a href={attachment.file.localFile.publicURL}>{attachment.name}</a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-6 border-t border-gray-200 pt-8"></div>
        </Container>
      </>
    );
  }
}

export default PageView;
