import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import PageView from "../../components/page-view";

function Page(props) {
  const { strapiPage } = props.data;
  return (
    <>
      <Layout>
        <PageView page={strapiPage} />
      </Layout>
    </>
  );
}

export const query = graphql`
  query($id: String!) {
    strapiPage(id: { eq: $id }) {
      title
      body
      excerpt
      slug
      imageCredits
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      content {
        title
        content
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      attachment {
        id
        file {
          localFile {
            publicURL
          }
        }
        name
      }
    }
  }
`;

export default Page;
